<template>
  <!-- 常见问题 -->
  <div>
 <van-nav-bar
      class="backBox"
      title="常见问题"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

  <div class="page">

    <main>
      <div class="question_top">
        <div class="question_top_item">
          <div class="tit">常见问题</div>
          <span class="tit_text">
            1.常见问题常见问题常见问题常见问题常见问题常见问题常见问题常见问题常见问题常见问题;
            2.常见问题常见问题常见问题常见问题常见问题常见问题;
          </span>
        </div>
        <div class="question_top_item">
          <div class="tit">特别声明</div>
          <span class="tit_text">
            1.常见问题常见问题常见问题常见问题常见问题常见问题常见问题常见问题常见问题常见问题;
            2.常见问题常见问题常见问题常见问题常见问题常见问题;
          </span>
        </div>
        <div class="question_top_item">
          <div class="tit">特别声明</div>
          <span class="tit_text">
            1.常见问题常见问题常见问题常见问题常见问题常见问题常见问题常见问题常见问题常见问题;
            2.常见问题常见问题常见问题常见问题常见问题常见问题;
          </span>
        </div>
      </div>

      <!-- ToDo 电子发票说明 -->
      <div class="question_explain">
        <div class="question_explain_item">
          <div class="tit">Q.{{}}去哪里可以开发票？</div>
          <span class="tit">
            A.{{}}<span class="tit_text"
              >在这里可以开发票在这里可以开发票在这里可以开发票在这里可以开发票在这里可以开发票在这里可以开发票在这里可以开发票</span
            >
          </span>
        </div>
      </div>

      <div class="quextion_list"></div>
    </main>
  </div>
    </div>
</template>
<script>
export default {
  data () {
    return {

    }
  },
  mounted () {

  },
  computed: {

  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped>
.page {
  overflow: hidden;
}
main {
  padding: 0.16rem;
  overflow-y: auto;
}
.question_top,
.question_explain {
  background-color: #fff;
  margin-bottom: 0.16rem;
  padding: 0.16rem;
}

.question_top_item {
  margin-bottom: 0.16rem;
  display: flex;
  flex-direction: column;
}
.tit {
  color: #333;
  font-size: 0.18rem;
  line-height: 0.18rem;
  text-align: left;
  margin-bottom: 0.08rem;
}
.tit_text {
  color: #999;
  font-size: 0.14rem;
  line-height: 0.2rem;
  text-align: left;
}
.question_explain_item {
  display: flex;
  flex-direction: column;
}
</style>
